.Live-test-series{
    color: var(--text-color);
    .assignment-expired{
        color: red;
    }
    .testSeries{
        margin-left: 5%;
        margin-right: 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        .testSeries-card{
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 4px rgba(0, 0, 0, 0.25);
            width: 400px;
            height: 200px;
            border-radius: 25px;
            position: relative;
            text-decoration: none;
            margin: 0;
            color: aliceblue;
            div{
                position: absolute;
                bottom: 10px;
                left: 20px;
                margin: 0;
            }
            p{
                margin: 5px 0 0 0;
                text-decoration: none !important;
            }
            img{
                margin-bottom: 20px;
                position: absolute;
                top:-5px;
                right: -13px;
            }
        }
    }
}