.ask-to-evaluate{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 2;
    .slot-selector{
        width: 400px;
        height: 200px;
        background-color: #effbff;
        margin: calc(100vh/2 - 240px/2) auto;
        border-radius: 10px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
        padding: 20px;
        select, .btns{
            width: 80%;
            display: block;
            margin: 30px auto 10px;
        }
        .btns{
            text-align: center;
            margin: 30px;
            button{
                width: 100px;
                margin: 10px;
                border: none;
                border-radius: 5px;
                color: #effbff;
                letter-spacing: 1.2px;
                height: 40px;
            }
            .cancel{
                background-color: red;
            }
            .enroll{
                background-color: #002A5D;
            }
            .enroll:disabled{
                cursor: no-drop;
            }
        }
    }
}