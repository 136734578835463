
.description{
    padding: 20px;
    .about,
    .course-content,
    .installment-plans{
        h4{
            font-family: 'Lato', sans-serif !important;
            font-weight: 800;
            font-size: 31px;
        }
        p{
            font-family: 'Lato', sans-serif !important;
            font-weight: 500;
            font-size: 18px;
        }
    }
    .course-content{
        position: inherit;
    }
}