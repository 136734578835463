.payments {
    color: var(--text-color);
}

.payment-card {
    width: max-content;
    padding: 30px;
    border-radius: 18px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25),
    inset 4px 4px 6px rgba(0, 0, 0, 0.25);
    align-items: center;
    margin: 10px;
    color: var(--text-color);
   
    display: flex;
    flex-direction: column;
    justify-content: space-between;



    img {
        height: 100%;
        width: 275px;
        margin: 20px auto;
    }

    .payment-details {
        width: 100%;
      

    }

}

.payment-card:nth-of-type(3n+1) {
    background-color: #E0A3B5;
}

.payment-card:nth-of-type(3n+2) {
    background-color: #A3E0D5;
}

.payment-card:nth-of-type(3n) {
    background-color: #E0CBA3;
}

.no-payments {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 5%;
}