.content-card{
    background-color: #dfebef;
    color: var(--text-color);
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 35px;
    border-radius: 13px;
    width: 70%;
    @media only screen and (max-width: 900px) {
        width: 100%;
    }
    .content-line1{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
           
            margin: 0;
        }
        .left-content{
            display: flex;
            div{
                margin-left: 10px;
            }
        }
    }
 
    .content-line2{
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a{
            
            font-weight: 400;
            font-size: 18px;
        }
        button{
            border: 0;
            background-color: transparent;
         
            font-weight: 400;
            font-size: 18px;
            
        }
        button:hover{
            text-decoration: underline;
        }
    }
}