.enroll-preb4pre {
    color: var(--text-color);
}

.enroll-btn{
    width: fit-content;
    text-align: center;
}

// .preb4pre-overview{
//     .test-details:hover{
//         border: 1.5px solid var(--text-color) !important;
//         cursor: pointer;
//     }
// }
