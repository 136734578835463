.test-card-info{
    div{
        p{
            font-size: 18px;
        }
    }
    table {
        border-collapse: collapse;
        width: 100%;
    }
    
    td, th {
        border: 1px solid #999;
        padding: 0.5rem;
        text-align: left;
    }
    
}
