.main {
    margin-left: 180px;
    .container{
        width: 100%;
        padding: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .schedule-card {
        display: flex;
        align-items: center;
        width: 100%;
        margin: auto;
        background: #2058DC;
        border-radius: 15px;
        padding: 37px 70px;
        // color: rgb(1, 42, 93);
        color: #fff;
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25),
        inset 4px 4px 6px rgba(0, 0, 0, 0.25);
        cursor: pointer;
        img {
            display: block;
            margin: 0 auto;
            margin-top: 30px;
            width: 30vw;
        }
        h1{
            display: inline-block;
            font-size: 2.1rem;
            width: 90%;
            margin: 10px 0;
        }
        p {
            display: inline-block;
            opacity: 0.7;
            width: 75%;
            margin: 10px 0;
        }
    }

    .progress {
        background-color: white;
        width: 100%;
        }
        .progress-bar {
            background-color: #FAB935!important;
        }
        
        .recent-card {
            background: #EEF1F6;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 22px;
            border-radius: 13px;
            width: 526px;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            .score {
                padding: 12px;
                border: solid 1px #BFB9B9;
                border-radius: 22px;
                width: 140px;
                margin-right: 20px;
            }
        }
        
        .banner{
            // display: flex !important;
            align-items: center;
            // margin: auto;
            background-color: #dfebef;
            box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25),
            inset 4px 4px 6px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            padding: 5%;
            color: var(--text-color);
            min-height: 325px;
            div{
                margin: 0;
                img{
                    display: block;
                    margin: 0 auto;
                    margin-bottom: 30px;
                    width: 20vw;
                }
                p{
                    font-size: 18px;
                }
                button{
                    display: block;
                    padding: 5px 40px;
                    border-radius: 10px;
                    border-style:none;
                    background-color: #002A5D;
                    color: white;
                    margin: 10px auto;
                }
                button:hover{
                    color: white;
                    background-color:rgba(0, 42, 93, 0.8);
                }
            }
        }
        
        .Slidecard1 {
            text-decoration: none;
            height: 400px;
            padding: 20px 20px;
            border-radius: 18px;
            box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25),
            inset 4px 4px 6px rgba(0, 0, 0, 0.25);
            // background: #e6ecf9;
            width: 98% !important;
            display: flex !important;
            align-items: center;
            justify-content: space-around;
            margin: 10px 0;
            cursor: pointer;
            .courseImage{
                height: 100px;
                width: 90%;
                margin-left: auto;
                margin-right: auto;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: scale-down;
                }
            }
            p {
                margin-top: 13%;
                font-size: 25px;
                text-decoration: none;
                color: rgb(1, 42, 93);
                text-align: center;
            }
            .authors{
                margin-top: 5%;
                font-size: 15px;
                color:rgba(1, 42, 93, 0.87) ;
                text-align: center;
            }
            .price{
                margin-top: 5%;
                font-size: 20px;
                color:rgb(1, 42, 93) ;
                text-align: center;
            }
        }
        .yellow{
            background: #e6ecf9;
        }
        .darkYellow{
            background-color: #e6ecf9;
        }
}
@media screen and (max-width:726px) {
    .main {
        margin-left: 0;
        .schedule-card {
            img {
                width: 50vw;
            }
        }
        .banner{
            div{
                img{
                    width: 40vw;
                }
            }
        }
    }
}

@media screen and (max-width:800px) {
    .main{
        .schedule-card{
            padding: 39px 43px;
        }
    }
}

.logout-btn{
    border: none;
    background: none;
    text-align: center;
    font-size: 17px;
    .logout{
        display: inline-block;
        border: 1px solid rgb(228, 67, 67);
        background-color: red ;
        border-radius: 50%;
        width: 30px;
        height: 30px;
    }
}


