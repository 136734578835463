.instruction-sections{
    margin: 20px;
    .section{
        width: 70%;
        margin: 10px auto 10px auto;
        p{
            text-align: center;
            font-weight: 500;
            font-size: 1.3rem;
            color: #035AA6;
        }
    }
}


.blur{
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.753);
    z-index: 2;
    .content{
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        width: 80%;
        height: 80vh;
        z-index: 2;
        background-color: rgb(255, 255, 255);
        margin: 10vh auto;
        border-radius: 15px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        svg{
            margin-bottom: 50px;
        }
        p{
            text-align: center;
            font-size: 1.1rem;
            font-weight: 600;
            letter-spacing: 0.6px;
        }
        button{
            margin: 25px;
            background-color: #035AA6;
            color: whitesmoke;
            padding: 10px 30px;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 0.8px;
            border: 0;
            border-radius: 5px;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        }
        button:hover{
            background-color: #003b6e;
            letter-spacing: 1px;
        }
    }
}


.test-Container{
    width: 100%;
    height: 100%;
    main{
        .Container{
            .section-btns{
                margin: 30px;
                margin-top: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                button{
                    border: 0;
                    margin: 0 20px;
                    height: 50px;
                    width: fit-content;
                    background-color: transparent;
                    color: black;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 10px;
                }
                .selected-section{
                    background-color: rgb(6, 105, 248);
                    color: white;
                }
                button:hover{
                    background-color: rgb(6, 105, 248);
                    color: white;
                }
            }
            .questions-Container{
                overflow-y: scroll;
                height: 360px;
                padding: 20px 10px;
                .questions-notfound{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 40px;
                }
            }
            .question-tracking{
                overflow-y: scroll;
                height: 360px;
                padding: 20px 10px;
                .tracker-container{
                    border: rgba(0, 0, 0, 0.253) solid 0.2px;
                    .tracker:first-child{
                        border-top: 0;
                    }
                    .tracker{
                        width: 100%;
                        background-color: #e4e7eb;
                        height: fit-content;
                        padding: 20px 10px;
                        border: rgba(0, 0, 0, 0.253) solid 0.2px;
                        border-right: 0;
                        border-left: 0;
                        .tracker-section{
                            width: fit-content;
                            height: 50px;
                            background-color: rgb(6, 105, 248);
                            color: white;
                            border-radius: 10px;
                            padding: 13px 20px;
                        }
                        .tracker-no-of-ques{
                            margin: 20px 0;
                        }
                        .tracker-balls{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .ball-cont{
                                display: flex;
                                align-items: center;
                                .ball{
                                    background-color: brown;
                                    margin: 10px;
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    border: 0.1px solid rgba(0, 0, 0, 0.295);
                                }
                                p{
                                    margin: 0;
                                }
                            }
                            
                        }
                    }
                    .tracker-reviews{
                        min-height: 50px;
                        h6{
                            margin: 10px;
                        }
                        .tracker-review-inner{
                            display: flex;
                            align-items: center;
                            
                            div{
                                color: white;
                                border-radius: 50%;
                                margin: 10px;
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                                background-color: rgba(255, 0, 0, 0.637);
                                text-align: center;
                                border: 1px solid rgba(0, 0, 0, 0.479);
                            }
                        }
                    }
                }
            }
        }
        .question-scroller{
            label{
                font-size: 18px;
                font-weight: 500;
            }
            input{
                width: 45px;
                text-align: center;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
            }
    
            /* Firefox */
            input[type=number] {
            -moz-appearance: textfield;
            }
            button{
                margin: 0 10px;
                width: 100px;
                border: none;
                background-color: #003e74;
                color: #dfebef;
                padding: 4px;
                border-radius: 5px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
                cursor: pointer;
            }
            button:hover{
                box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.4);
            }
        }
        .submitBtn{
            margin: 30px auto;
            color: white;
            border-radius: 15px;
            padding: 10px 50px 10px 50px;
            background-color: rgb(6, 105, 248);
            border: 0;
            display: block;
        }
    }
    .file-preview-container{
        background-color: rgba(0, 0, 0, 0.493);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 5;
        .file-preview-card{
            width: 80%;
            height: 80vh;
            background-color: #e4e7eb;
            margin: 10vh auto ;
            border-radius:10px ;
            box-shadow: 0  0 5px  rgba(0, 0, 0, 0.493);
            position: relative;
            .close_btn{
                position: absolute;
                top:10px;
                right: 10px;
                border: none;
                background-color: red;
                color: #fff;
                border-radius: 50%;
                padding: 1px;
                width: 28px;
                height: 28px;
                font-weight: 700;
                transition: 0.3s;
            }
            .close_btn:hover{
                color: #fff;
                transition: 0.3s;
                background-color: rgb(172, 9, 9);
            }
            display: flex;
            align-items: center;
            justify-content: center;
            .file{
            
                .pdf{
                    height: 80vh;
                    overflow-x: hidden;
                    border-radius: 10px;
                }
                .pdf::-webkit-scrollbar {
                    width: 5px;
                }
                
                .pdf::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }
                
                .pdf::-webkit-scrollbar-thumb {
                    background-color: darkgrey;
                    outline: 1px solid slategrey;
                }
                img{
                    max-width: 100%;
                    max-height: 80vh;
                }
            }
        }
    }
    .file-delete-container{
        background-color: rgba(0, 0, 0, 0.493);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 5;
        display: flex;
        justify-content: center;
        align-items: center;
        .file-delete-card{
            width: 310px;
            height: 150px;
            background-color: #e4e7eb;
            padding: 25px 20px;
            border-radius: 10px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.479);
            display: flex;
            justify-content: center;
            flex-direction: column;
            p{
                font-size: 18px;
                text-align: center;
                font-weight: 700;
            }
            div{
                display: flex;
                justify-content: center;
                button{
                    width: 100px;
                    height: 30px;
                    margin: 0 12px;
                    border: none;
                    cursor: pointer;
                    border-radius: 5px;
                    color: #fff;
                    font-weight: 400;
                }
            }
        }
    }
}

.backBtn{
    width: 40px ;
    margin: 40px ; 
    cursor: pointer ;
    background-color:#312d2d3a ;
    padding: 10px;
    margin: 0;
    border-radius: 20%;
}

