.assignments-container{
    width: fit-content;
    margin: 10px auto;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-evenly;
    flex-wrap: wrap;
    padding: 20px;
    .assignment-card{
        position: relative;
        width: 250px;
        height: 300px;
        max-height: fit-content;
        margin: 20px;
        background-color: #e6ecf9;
        border-radius: 22px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
        img{
            position: absolute;
            top: -5px;
            right: -13px;
            
        }
        .assignment-btns{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 99px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 10px;
            button{
                width: 120px;
                display:flex ;
                flex-direction: row;
                margin: 3px;
                svg{
                    margin-right: 10px;
                }
                border: 1px solid #28395e;
                background-color: #28395e;
                color: #e6ecf9;
                padding: 3px;
                border-radius: 7px;
                box-shadow: inset 0 4px 8px rgba(255, 255, 255, 0.212);
            }
        }
        .assignment-heading{
            position: absolute;
            top: 100px;
            left: 0;
            right: 0;
            height:50px;
            overflow:hidden ;
            h1{
                font-size: 18px;
                text-align: center;
            }
        }
        .assignment-sub-heading{
            position: absolute;
            top: 151px;
            left: 0;
            right: 0;
            height: 80px;
            padding: 0 15px;
            overflow: hidden;
            h2{
                font-size: 14px;
                text-align: center;
            }
        }
        .assignment-info{
            position: absolute;
            top: 235px;
            left: 0;
            right: 0;
            height: 60px;
            padding: 10px;
            overflow: hidden;
            p{
                font-size: 13px;
                margin: 0;
                color: #28395e;
                font-style: italic;
                font-weight: 600;
            }
        }
    }
}