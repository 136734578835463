.preb4pretests {
    padding: 2%;
}

.upcoming-tests {

    padding-right: 5%;
    padding-bottom: 100px;
    padding-left: 5%;

.active-test-card {
    text-decoration: none;
    margin: auto;
    background-color: #dfebef;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25), inset 4px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 30px;
    color: var(--text-color);
    min-height: 40vh;
    height: 100%;
    img {
        display: block;
        width: 200px;
        margin-left: auto;
        margin-right: auto;
    }
    p {
        font-weight: 500;
        text-decoration: none;

    }

    button {
        padding: 5px 40px;
        border-radius: 10px;
        border-style:none;
        background-color: #002A5D;
        color: white;
        margin: 10px 0;
        cursor: pointer;
    }
}

}
@media only screen and (max-width: 1000px)  {
    .upcoming-tests {
        .active-test-card {
            img{
                width: 100px !important;
            }
        }
    }
}

.upcoming-tests {
    .test-card {
        background-color: #dfebef;
        padding: 20px;
        margin: 10px;
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25), inset 4px 4px 6px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
     
        width: 94% !important;
        height: fit-content;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: row;

        img {
            display: block;
            width: 200px;
            margin: auto;
        }

        p {
            
            font-weight: 600;
            color: rgb(1, 42, 93);
            word-wrap:break-word;
        }
        .slick-prev:before {
            color: rgb(1, 42, 93);
            font-size: 30px;
        }
        .slick-next:before {
            color: rgb(1, 42, 93);
            font-size: 30px;
        }
        .slots{
            div{
                width: 80%;
                margin: 10px auto;
                text-align: center;
                background-color: #002A5D;
                box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25), inset 4px 4px 6px rgba(0, 0, 0, 0.25);
                padding: 20px;
                border-radius: 20px;
                h4{
                    margin-bottom: 30px;
                    font-weight: 600;
                    color: #f8f8f8;
                }
                p{
                    font-weight: 600;
                    color: #f8f8f8;
                    span{
                        font-size: 18px;
                    }
                }
            }
        }
    }
    .yellow{
        background: #f7ce17;
    }
    .darkYellow{
        background-color: #FAB935;
    }

}

@media only screen and (max-width: 1000px)  {
    .upcoming-tests {
        .test-card {
            img{
                // display: block;
                width: 100px !important;
            }
        }
    }
}

.attempted-test,.preb4pretests {
    .testSeries{
        margin-left: 2%;
        margin-right: 2%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        .testSeries-card{
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 4px rgba(0, 0, 0, 0.25);
            width: 400px;
            height: 250px;
            border-radius: 25px;
            position: relative;
            text-decoration: none;
            color: aliceblue;
            margin: 0;
            color: aliceblue;
            div{
                position: absolute;
                bottom: 10px;
                left: 20px;
                margin: 0;
            }
            p{
                margin: 5px 0 0 0;
                text-decoration: none !important;
            }
            img{
                margin-bottom: 20px;
                position: absolute;
                top:-5px;
                right: -13px;
            }
        }
    }
}

.upcoming-test-list{
    height: fit-content;
    a{
        text-decoration: none;
        display: flex !important;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        height: fit-content;
        .assignment-card {
            position: relative;
            width: 200px;
            height: 220px;
            border-radius: 25px;
            text-decoration: none;
            color: aliceblue;
            margin: 0;
            div{
                position: absolute;
                bottom: 10px;
                left: 20px;
                margin: 0;
            }
            p{
                margin: 5px 0 0 0;
                text-decoration: none !important;
            }
            img{
                margin-bottom: 20px;
                position: absolute;
                top:-5px;
                right: -13px;
            }
        }
    }
}


.ongoing-test-list{
    a{
        text-decoration: none;
        display: flex !important;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        .active-test-card {
            position: relative;
            width: 94% !important;
            height: 300px;
            border-radius: 25px;
            text-decoration: none;
            color: rgb(1, 42, 93);
            margin: 10px;
            box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25), inset 4px 4px 6px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            flex-direction: row;
            p{
                margin: 5px 0 0 0;
                text-decoration: none !important;
            }
            img{
                display: block;
                width: 200px;
                margin: auto;
            }
            .slick-prev:before {
                color: rgb(1, 42, 93);
                font-size: 30px;
            }
            .slick-next:before {
                color: rgb(1, 42, 93);
                font-size: 30px;
            }
            .slots{
                div{
                    width: 80%;
                    margin: 10px auto;
                    text-align: center;
                    background-color: #002A5D;
                    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25), inset 4px 4px 6px rgba(0, 0, 0, 0.25);
                    padding: 20px;
                    border-radius: 20px;
                    h4{
                        margin-bottom: 30px;
                        font-weight: 600;
                        color: #f8f8f8;
                    }
                    p{
                        font-weight: 600;
                        color: #f8f8f8;
                        span{
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
    .yellow{
        background: #f7ce17;
    }
    .darkYellow{
        background-color: #FAB935;
    }
}
