.profile-container {
    display: flex;
    align-items: center;
  img {
    border-radius: 50px;
    width: 96px;
    height: 96px;
    margin-right: 20px;
}
.welcome {
    margin: 0;
    font-size: 19px;
    color: #012A5D;
}
.name {
    margin: 0;
    font-size: 29px;
    color: #012A5D;
    font-weight: 600;
}
}

.more-container {
  .more-card {
   // background: #FFD30E;
    background-color: #FAB935;
    display: flex;
    justify-content: space-between;
    padding: 22px 44px;
    align-items: center;
    border-radius: 12px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    cursor: pointer;
    .first-child {
        // align-items: start;
        display: flex;
        img {
            margin-right: 11px;
            margin-top: 5px;
        }
        .heading {
            margin: 0;
            font-size: 24px;
            font-weight: 500;
            color: white;
        }
        .p {
            margin: 0;
            color: white;
            opacity: 0.8;
        }
        }
    }
  }

  @media (max-width:600px) {
      .more-image {
          visibility: hidden;
          display: none;
      }
  }
