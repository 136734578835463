// * {
//   overflow: hidden;
// }
.image-wrapper, .image-pd-wrapper {
    background-color: #0669F8;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
    img {
      width: 34vw;
      margin-bottom: 65px;
    }
    
    p {
      font-size: 31px;
      color: white;
      text-align: center;
      font-weight: 300;
    }
  }


  
  .form-wrapper {
    padding: 47px;
    // background-color: var(--app-secondary);
  
    img {
      display: block;
      width: 172px;
      margin: 0 auto;
    }
    h1 {
      font-size: 30px;
      font-weight: 400;
      margin-top: 7vh;
      line-height: 50px;
      color: var(--text-color);
      span {
          font-weight: 700; 
      }
    }
  }
  
  
  
  .bg {
      margin-bottom: 20px;
  }
  
  
  
  .input-cont {
      position: relative;
      height: 70px;
      overflow: hidden;
  }
  



  .input-cont input {
      width: 100%;
      height: 100%;
      color: #111;
      // padding-top: 20px;
      border: none;
      outline: none;
      background: transparent;
  }
  
  .input-cont label {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      border-bottom: 2px solid var( --text-color);
  }
  
  .input-cont label::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 100%;
      height: 100%;
  }
  label {
      margin: 0;
      color: var( --text-color);
  }
  
  .content-name {
      position: absolute;
      bottom: 5px;
      left: 0;
      transition: all .3s ease-out;
  }
  
  .input-cont input:focus + .label-name .content-name, .input-cont input:valid + .label-name .content-name {
      transform: translateY(-150%);
      font-size: 14px;
      color: var( --text-color);
  }
  
  .input-cont input:focus + .label-name::after, .input-cont input:valid + .label-name::after {
      transform: translateX(0%);
  }

.next-container{
    display: flex;
    justify-content: center;
    margin: 0;
  .next-btn {
    border: none;
    background: var( --text-color);
    padding: 20px;
    border-radius: 50% !important;
    img {
      width: 24px !important;
      height: 22px;
    }
  }
}

.next-btn {
  border: none;
  background: var( --text-color);
  padding: 20px;
  border-radius: 50% !important;
  img {
    width: 24px !important;
    height: 22px;
  }
}


.googleBtnContainer{
  display: flex;
  height: fit-content;
  width: 100%;
  align-items: center;
  border-radius: 15px!important;
  justify-content: center;
  
 

  button {
    border-radius: 25px!important;
    border: 1px solid var(--text-color)!important;
    background: none!important;
    box-shadow: none!important;
    color: var(--text-color)!important;

    div {
    background: none!important;
    }
  }

  
}
.googleBtn{
  color: white;
  background-color: #0669F8;
  font-weight: 300;
  font-size: 15px;
  text-align: center;
  height: 100%;
  border-radius: 15px;
  padding: 10px;
}

.googleIcon{
  padding:5px;
  height: 100%;
  width: 45px;
  border-radius: 15px;
  border: 0.2px #0669F8 solid ;
  background-color: aliceblue;
} 

@media (max-width:600px) {
  .image-wrapper {
   display: none;
   visibility: hidden;

  }

  .form-wrapper {
    padding: 25px;
  }
}