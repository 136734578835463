.profile-logo{
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    .img-container{
        position: relative;
        background-color: rgb(255, 255, 68);
        width: 30%;
        height: 55px;
        border-radius: 50%;
        margin-right: 5px;
        border: 1px solid #77b0ff;
        img{
            height: 100% !important;
            width: 100% !important;
            border-radius: 50%;
        }
        .img-letter{
            h1{
                margin: 0;
                color: #0669F8;
                padding: 2px;
            }
        }
    }
    .name-container{
        width: 65%;
        height: 60px;
        padding-top: 15px;
        h3{
            color: #fff;
            margin: 0!important;
        }
    }
}