.my-test-series-s-effect{
    .search-box {
        display: flex;
        align-items: center;
        padding:  0 20px;
        background: var(--text-color);
        color: white !important;
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        width: 70%;
        min-width: 250px;
        margin: 0 auto 20px auto;
        input {
            padding:  15px;
            color: white;
        }
    }
    .testSeries{
        margin-left: 5%;
        margin-right: 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        .testSeries-card{
            background: #e2e7f3;
            height: 200px;
            width: 100%;
            border-radius: 25px;
            position: relative;
            text-decoration: none;
            color: aliceblue;
            .test{
                padding-top: 110px ;
                .text{
                    width: 100%;
                    height: .8rem;
                    margin-bottom: .25rem;
                    border-radius: .125rem;
                }
                .text:last-child{
                    width: 70%;
                }
            }
        }
    }
}
