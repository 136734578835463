@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Lora:ital,wght@0,400;0,600;0,700;1,500&display=swap');
.payment-details{
    .container{
        height: fit-content;
        padding: 20px;
        .bill-Details,
        .bill-pay{
            height:200px;
            margin:20px;
            background-color: aliceblue;
            padding: 20px;
            h4,h5{
                font-family: 'Lato', sans-serif;
            }
            h4{
                font-weight: 700;
            }
            h5,p{
                font-weight: 400;
                color: #606469;
                margin:0 0 5px 0;
            }
            button{
                border: none;
                margin: 15px 0;
                background-color: #3683dc;
                padding: 2px 20px;
                color: #fff;
                font-weight: 500;
                font-family: 'Lato', sans-serif;
            }
            button:hover{
                background-color: #246abb;
            }
        }
        .bill-history{
            
            background-color: aliceblue;
            padding: 20px;
            margin:40px 20px;
            h4,h3{
                font-weight: 700;
                font-family: 'Lato', sans-serif;
            }
            table{
                width: 1000px;
                tr{
                    height: 50px;
                }
            }
        }
        .bill-Details,
        .bill-pay,
        .bill-history{
            border: 0.5px solid rgba(0, 0, 0, 0.11);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.274);
            border-radius: 10px;
        }
    }
    .payment-modal h4{
        font-weight: 700 !important;
        font-family: 'Lato', sans-serif !important;
        table{
            width: 100%;
            tr{
                height: 50px;
            }
        }
    }
}

.amount{
    h5{
        font-weight: 700;
        font-family: 'Lato', sans-serif;
    }
    input[type=number]{
        appearance: none;
    }
}

.amount-btn{
    margin: 0px auto ;
    width: 100px ;
    display: block ;
    margin-top: 31px ;
    border: none;
    background-color: #3683dc;
    padding: 4px 20px;
    color: #fff;
    font-weight: 500;
    font-family: 'Lato', sans-serif;
}