.mycoursesLoading{
    .skeleton{
        animation: skeleton-loading 1s linear infinite alternate;
    }
    @keyframes skeleton-loading {
        0%{
            background-color: hsl(200, 20%, 70%);
        }

        100%{
            background-color: hsl(200, 20%, 95%);
        }
    }
    .box{
        border-radius: 18px;
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25),
        inset 4px 4px 6px rgba(0, 0, 0, 0.25);
        background: #e6ecf9;
        display: flex !important;
        padding: 40px 20px;
        flex-direction: column;
        text-align: center;
        cursor: pointer;
        width: 94% !important;
        min-height: 320px;
        height: fit-content;
        margin: 10px 10px;
        align-items: center;
        justify-content: space-around;
        .img {
            width: 123px;
            height: 123px;
            border-radius: 50%;
            object-fit: scale-down;
            margin: auto;
            margin-bottom: 30px;
        }
        .text{
            width: 100%;
            height: .8rem;
            margin-bottom: .25rem;
            border-radius: .125rem;
        }
        .text:last-child{
            width: 70%;
            margin-left: auto;
            margin-right: auto;
        }
    }
    .lesson{
        .content-card{
            background-color: #dfebef;
            color: var(--text-color);
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            padding: 35px;
            border-radius: 13px;
            width: 70%;
            @media only screen and (max-width: 900px) {
                width: 100%;
            }
           
            .text{
                width: 100%;
                height: .8rem;
                margin-bottom: .25rem;
                border-radius: .125rem;
            }
            .text:last-child{
                width: 70%;
            }
        }
    }
    
}

