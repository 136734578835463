.main {
    margin-left: 200px;
    .backBtn{
        width: 40px ;
        margin: 40px ; 
        cursor: pointer ;
        background-color:#312d2d3a ;
        padding: 10px;
        margin: 0;
        border-radius: 20%;
    }
}

.chapters-list {
    min-height: 100vh;
    background-color: white;

    .chapters {
        margin: 0 20%;
    }

}

.back-btn-section {
    background-color: #0669F8;
    border-radius: 0 0 15px 15px;
    color: white;
    display: flex;
    align-items: center;
    height: 140px;
    div{
        margin-right:20px ;
        margin-left: 20px;
        cursor: pointer;
    }
    h2{
        margin: 0;
    }
}

.modal-view-new {
    position: fixed;
    left:0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--text-color);
    display: flex;
    align-items: center;
    justify-content: center;
        z-index: 1;
        text-align: justify;
        height: 100vh;
        color: var(--text-color);

        .modal-custom-content {
            color: var(--font-color);
            padding: 10px;
            width: 100%;
            box-shadow: 4px 4px 6px rgba(0 ,0, 0 , 25%), inset 0px 0px 6px rgba(0, 0, 0 , 25%);
            border-radius: 20px;
            background-color: #dfebef;        
            height: 100%;
             margin: auto;
            padding: 25px;

            .video-styles {
                width: 60vw;
                height: 60vh;
                margin: auto;
            }

            .audio-styles {
               
                height: 50vh;

                audio {
                    width: 100%;
                }
            }
        }

        .modal-custom-header {
            padding: 0px;
        }

        .modal-custom-footer {

            justify-content: space-between;
           

            button {
                padding: 5px 40px;
                border-radius: 10px;
                border-style:none;
                background-color: #002A5D;
                color: white;
                margin: 10px;
                width: max-content;
            }

        }
}


.link{
    margin-top:10px;
    margin-right:20px;
    cursor: pointer;
 
}

.link:hover{
    color: rgba(0, 42, 93,0.5);
}


.continue-card {
   
    color: var(--text-color);
padding: 2%;
    .graph-icon {
        padding: 5px;
        box-shadow: 1px 1px 6px rgba(0, 0 ,0 , 25%);
        border-radius: 5px;
    }


}

.highlight-card {
    background-color: rgba(96, 168, 255, 0.4);
}

@media screen and (max-width:726px) {
    .main {
        margin-left: 0;
    }
    .back-btn-section{
        position: fixed;
        top:0;
        right: 0;
        left: 0;
        z-index: 33;
    }

    .modal-view-new {
        position: fixed;
        left:0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: var(--text-color);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
        text-align: justify;
        height: 100vh;
        color: var(--text-color);

        .modal-custom-content {
            color: var(--font-color);
            padding: 10px;
            width: 100%;
            box-shadow: 4px 4px 6px rgba(0 ,0, 0 , 25%), inset 0px 0px 6px rgba(0, 0, 0 , 25%);
            border-radius: 20px;
            background-color: #dfebef;        
            height: 100%;
                margin: auto;
            padding: 25px;

            .video-styles {
                width: 60vw;
                height: 60vh;
                margin: auto;
            }

            .audio-styles {
                
                height: 50vh;
                display: flex;
                justify-content: center;
                align-items: center;
                

                audio {
                    width: 80%;
                }
            }
        }

        .modal-custom-header {
            padding: 0px;
        }

        .modal-custom-footer {
            button {
                padding: 5px 40px;
                border-radius: 10px;
                border-style:none;
                background-color: #002A5D;
                color: white;
                margin: 10px;
            }
        }
    }

    .continue-card{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
        .left-content{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .content-notAvail{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h3{
            margin-top: 20px;
            margin-bottom: 20px;
        } 
    }
    .chapters-list {
        min-height: 100vh;
        background-color: white;
        padding-top: 100px;

        .chapters {
            margin: 0 5%;
        }

    }
}
