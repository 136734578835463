.invoices-btn {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap:wrap;
    font-size: 20px;
    margin: 0 0 20px 20px;
 
    .highlight-btn {
        background-color: #002A5D;
        color: #e6ecf9;
    }
 
    button {
        margin: 10px;
        background: none;
        border: 1px solid #002A5D ;
        border-radius: 25px;
        background-color: transparent;
        width: 170px;
        color: var(--text-color);
        padding: 10px 0;
    }
 
    button:hover {
        transform: none;
    }
}
 
 .invoices-options {
     margin-top: 20px;
     padding: 10px 10px;
     background: white;
     border-radius: 25px;
     border-style: none;
     border-bottom: outset;
     border-right: outset;
     width: fit-content;
     color: #002A5D;
 
     select {
         border: none;
         background: none;
     }
 }
 