// .course-banner {
//     // background: url("https://i.ibb.co/cYwKfXd/1.jpg");
//     // background-repeat: no-repeat;
//  object-fit: contain;
//     height: 50vh;
//     width: 100%;
//     background-color: var(--text-color);
//     padding: 10px;

//     img {
//         width: 300px;
//         display: block;
//         margin: auto;

//     }
// }

.course-overview {
    padding: 2% 0;
    color: var(--text-color);
    background-color: var(--background-color);
}

.course-content {
    border-radius: 31px;
    position: relative;
    h1,h2,h3 {
        color: var(--text-color);
    }
}
// .about-course {
//     width: 70%;
// }

   
    .enroll-btn {
        padding: 16px 32px;
        background: #007bff;
        color: white;
        border-radius: 8px;
        // float: right;
    }


    .no-enroll-btn {
        padding: 5px 40px;
        border-radius: 10px;
        border-style:none;
        background-color: #002A5D;
        color: white;
        margin: 10px;
    }

.course-container {
    background-color: var(--background-color);
    color: var(--text-color);
    padding: 20px 0;
    min-height: 75vh;
}
.courseimg{
    display: grid;
    justify-content: center;
    justify-items: center;
    margin-left: 50%;
}
.courseimg img{
    text-align: right;
    margin-left: -220%;
    margin-right: 10px;
    margin-bottom: -50%;

}
.courseimg h1{
    text-align: right;
    margin-left: 300%;
}

.courseimg{
    display: grid;
    justify-content: center;
}
/* .course-info-landing #theimage{
    width: 200px;
    text-align: center;
    vertical-align: middle;
} */
.course-container{
    color:var(--text-color);
    margin-bottom: 100px;
}

.course-info-landing{
    background-color: var(--background-color);
    color: #fff;
    height: 40vh;
    border-radius: 0px 0px 20px 20px;
}

.course-info-landing h1{
    justify-self: center;
    align-self: center;
    margin: -20px 0px 10px 0px;
    padding-right: 20px;
    padding-left: 20px;
    font-size: 80px;
    letter-spacing: 2px;
}
.course-info-landing img{
    padding-left: 20px;
    margin-top: 20px;
    cursor: pointer;
    color: red;
}

.course-info-landing a{
    color: #fff;
    margin-left: 30px;
    cursor: pointer;
}
.course-info-landing a:hover{
    color: #fff;
}

.course-info{
    margin:30px 90px;
}

.course-info p {
    white-space: pre-wrap;
}
.topics{
    background-color: #EEF1F6;
    padding: 20px;
    border-radius: 6px;
    box-shadow:2px 2px 6px rgba(0,0,0,0.3);
    
}
.topics > div{
    margin-bottom: 30px;
}
.topics > div:last-child{
    margin-bottom: 0px;
}
.topics li{
    list-style-type: none;
    margin-bottom: 10px;
    margin-left: 10px;
}

.faq-section{
    margin: 0 auto;
}
.coupons,.faqs{
    margin-top: 20px;
}
.faqs h2{
    margin-bottom: 20px;
}

.subjects-info p{
   margin: 0;
}

.subjects-info img {
    height: 21px;
}

.accordian-heading{
    font-size: 32px;
    font-weight: 600;
}

.course-importance{
    margin-top: 70px;
}
.course-importance li{
    font-size: 20px;
    margin-bottom: 20px;
}

@media(max-width: 767px){
    .course-info{
        margin:30px;
    }
    .course-info-landing h1{
        font-size: 50px;
        padding-right: 0px;
    }
    .course-info-landing img{
        display: none;
    }
    .topics{
        margin-bottom: 50px;
    }
    .course-importance  li{
        font-size: 19px;
    }
}

.coupons{
    div{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: left;
        input { 
            border-style: none; 
            background: transparent; 
            outline: none; 
            border: 1px solid #002A5D;
            width: 100%;
            height: 40px;
            border-radius: 10px 0 0 10px;
            padding: 10px;
        }
        button { 
            padding: 0; 
            background: none; 
            border: none; 
            outline: none; 
            background-color:#002A5D ;
            width: 130px;
            height: 40px;
            border-radius: 0 10px 10px 0;
            color: #EEF1F6;
        }
    }
    p{
        padding-top: 5px;
        color: red;
    }
}