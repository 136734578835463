#header {
    position: fixed;
    width: 220px;
  }
  #header .pro-sidebar {
    height: 100vh;
  }
  #header .closemenu {
    color: #000;
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 20px;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
    top: 55px;
    cursor: pointer;
  }
  #header .pro-sidebar {
    width: 100%;
    min-width: 100%;
  }
  #header .pro-sidebar.collapsed {
    width: 80px;
    min-width: 80px;
  }
  #header .pro-sidebar-inner {
    background-color: #0669F8;
    box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
    border-radius: 0 15px 15px 0;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout {
    overflow-y: hidden;

  }
  #header .pro-sidebar-inner .pro-sidebar-layout .logotext {
    text-align: center;
    padding: 19px;
    img {
      width: 154px;
    }
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul {
    padding: 0 ;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
    color: #fff;
    margin: 10px 0px;
    // font-weight: bold;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper {
    background-color:transparent;
    color: #fff;
    border-radius: 3px;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item .pro-icon-wrapper .pro-item-content {
    color: #fff;
  }
  #header .pro-sidebar-inner .pro-sidebar-layout .active {
    background: #165bbfad;
  }
  #header .logo {
    padding: 20px;
  }

  .pro-sidebar-header {
      border: none!important;
  }
  
  @media only screen and (max-width: 726px) {
    // html{
    //   padding-top:91px ;
    // }
    .mobile-view-sidebar {

      position: fixed;
      top: 0;
      background-color: #0669F8;
      color: white;
      width: 100%;
      z-index: 120;

      button {
        background: none;
        border: none;
        color: white;
      }

    }

    .mobile-header {
      .pro-sidebar {
        width: 100%;
        background: #0669F8;
      }
      .pro-sidebar-inner {
        background-color: #0669F8;
        box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
        border-radius: 0 21px 21px 0;
      }
    }

    .logotext-mv {
      img {
        width: 50%;
      }
    }

    html {
      overflow: hidden;
    }
  }