.graph-section{
    width: 70%;
    height: 50vh;
    margin:30px auto;
    padding: 20px 30px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 25px 50px -10px, 
        rgba(0, 0, 0, 0.3) 0px 15px 30px -15px, 
        rgba(10, 37, 64, 0.35) 0px -1px 3px 0px inset;
    border-radius: 20px;
    border: none
}