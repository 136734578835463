.InstructionLoading{
    .skeleton{
        animation: skeleton-loading 1s linear infinite alternate;
    }
    @keyframes skeleton-loading {
        0%{
            background-color: hsl(200, 20%, 70%);
        }

        100%{
            background-color: hsl(200, 20%, 95%);
        }
    }
    .test-profile{
        .img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
            object-fit: scale-down;
            margin: auto;
            margin-bottom: 30px;
        }
        .test-profile-info{
            padding: 20px;
        }
    }
    .text{
        width: 100%;
        height: .8rem;
        margin-bottom: .25rem;
        border-radius: .125rem;
    }
    .text:last-child{
        width: 70%;
    }
}