.pdf-container{
    height: 100vh;
    width: 100%;
    background-color: rgb(82, 86, 89);
    position: relative;
    min-width: 500px;
    overflow: scroll;
    .pdf-nav{
        height: 55px;
        width: 100%;
        background-color:rgb(50, 54, 57);
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        min-width: 500px;
        display: flex;
        justify-content: space-between;
        z-index: 2;
        .pdf-left-content{
            width: 33%;
            display: flex;
            justify-content: center;
            h5{
                width: fit-content;
                margin:0 ;
                margin: 15px auto;
                color: #fff;
            }
        }
        .pdf-center-content{
            width: 350px;
            display: flex;
            justify-content: space-around;
            margin: 15px auto;
            color: #fff;
            font-family: Roboto, 'Segoe UI', Tahoma, sans-serif;
            font-size: 0.81rem;
            align-items: center;
            .pdf-rotate button,
            .pdf-zoom button{
                background: transparent;
                border: none;
                color: #fff;
                font-size: 14px;
                cursor: pointer;
                width: 35px;
                height: 35px;
                padding: 5px;
            }
            .pdf-rotate button:hover,
            .pdf-zoom button:hover{
                background-color: rgba(255, 255, 255, 0.123);
                border-radius: 50%;
            }
            .pdf-input{
                width: 20px ;
                background-color: rgba(0, 0, 0, 0.342);
                padding: 1px;
                text-align: center;
                color: #fff;
                height: 16px;
                border: 1px solid #fff;
                border-radius: 4px;
            }
        }
        
        .pdf-right-content{
            width: 33%;
            min-width: 100px;
            display: flex;
            justify-content: center;
            button{
                background-color: rgb(172, 4, 4);
                border: none;
                width: 60px;
                font-weight: 800;
                color: #fff;
            }
            button:hover{
                background-color: rgb(212, 7, 7);
            }
        }
    }
    .pdf-document{
        margin-top:55px;
    }
    .pdf-page canvas{
        margin: 20px auto;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.699);
    }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}