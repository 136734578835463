.instruction-sections{
    margin: 20px;
    .section{
        width: 70%;
        margin: 10px auto 10px auto;
        p{
            text-align: center;
            font-weight: 500;
            font-size: 1.3rem;
            color: #035AA6;
        }
    }
}


.blur{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.753);
    z-index: 2;
    .content{
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        width: 80%;
        height: 80vh;
        z-index: 2;
        background-color: rgb(255, 255, 255);
        margin: 10vh auto;
        border-radius: 15px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        svg{
            margin-bottom: 50px;
        }
        p{
            text-align: center;
            font-size: 1.1rem;
            font-weight: 600;
            letter-spacing: 0.6px;
        }
        button{
            margin: 25px;
            background-color: #035AA6;
            color: whitesmoke;
            padding: 10px 30px;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 0.8px;
            border: 0;
            border-radius: 5px;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        }
        button:hover{
            background-color: #003b6e;
            letter-spacing: 1px;
        }
    }
}


.test-Container{
    width: 100%;
    height: 100%;
    min-width: 700px;
    main{
        .Container{
            .section-btns{
                margin: 30px;
                margin-top: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                button{
                    border: 0;
                    margin: 0 20px;
                    height: 50px;
                    width: fit-content;
                    background-color: transparent;
                    color: black;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 10px;
                }
                .selected-section{
                    background-color: rgb(6, 105, 248);
                    color: white;
                }
                button:hover{
                    background-color: rgb(6, 105, 248);
                    color: white;
                }
            }
            .questions-Container{
                overflow-y: scroll;
                height: 360px;
                padding: 20px 10px;
                .questions-notfound{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 40px;
                }
            }
            .question-tracking{
                overflow-y: scroll;
                height: 360px;
                padding: 20px 10px;
                .tracker-container{
                    border: rgba(0, 0, 0, 0.253) solid 0.2px;
                    .tracker:first-child{
                        border-top: 0;
                    }
                    .tracker{
                        width: 100%;
                        background-color: #e4e7eb;
                        height: fit-content;
                        padding: 20px 10px;
                        border: rgba(0, 0, 0, 0.253) solid 0.2px;
                        border-right: 0;
                        border-left: 0;
                        .tracker-section{
                            width: fit-content;
                            height: 50px;
                            background-color: rgb(6, 105, 248);
                            color: white;
                            border-radius: 10px;
                            padding: 13px 20px;
                        }
                        .tracker-no-of-ques{
                            margin: 20px 0;
                        }
                        .tracker-balls{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .ball-cont{
                                display: flex;
                                align-items: center;
                                .ball{
                                    background-color: brown;
                                    margin: 10px;
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    border: 0.1px solid rgba(0, 0, 0, 0.295);
                                }
                                p{
                                    margin: 0;
                                }
                            }
                            
                        }
                    }
                    .tracker-reviews{
                        min-height: 50px;
                        h6{
                            margin: 10px;
                        }
                        .tracker-review-inner{
                            display: flex;
                            align-items: center;
                            
                            div{
                                color: white;
                                border-radius: 50%;
                                margin: 10px;
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                                background-color: rgba(255, 0, 0, 0.637);
                                text-align: center;
                                border: 1px solid rgba(0, 0, 0, 0.479);
                            }
                        }
                    }
                }
            }
        }
        .submitBtn{
            margin: 30px auto;
            color: white;
            border-radius: 15px;
            padding: 10px 50px 10px 50px;
            background-color: rgb(6, 105, 248);
            border: 0;
            display: block;
        }
    }
}


.paragraph{
    margin: 30px 0px;
    padding: 30px 50px;
    border: rgba(0, 0, 0, 0.253) solid 0.2px;
    min-width: 390px;
    min-height: 400px;
    border-radius: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    .image{
        // min-width: 390px;
        width: 600px;
        margin: 30px auto 60px auto;
        border-radius: 15px;
        img{
            width: 100%;
            display: inline-block;
        }
    }
    @media screen and (max-width:850px) {
        .image{
            width: 100%;
        }
    }
}


.itw {
    .testSeries{
        margin-left: 2%;
        margin-right: 2%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        .testSeries-card{
            height: 320px;
            border-radius: 10px;
            position: relative;
            text-decoration: none;
            background-color: #e6ecf9;
            color: aliceblue;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
            margin: 0;
            .image{
                width: 90%;
                height: 40%;
                margin: 20px auto 10px auto;
                img{
                    width: 100%;
                    padding: 10px;
                }
            }
            .text{
                margin-top: 30px;
                color: #003b6e;
                text-align: center;
                p{
                    margin: 5px 0 0 0;
                    text-decoration: none !important;
                    font-size: 18px;
                    opacity: 0.7;
                }
                p:first-child{
                    opacity: 1;
                    font-size: 25px;
                    font-weight: 600;
                }
            }
        }
        .testSeries-card:hover{
            box-shadow: 0 3px 6px rgba(0, 0, 0, 0.322), 0 3px 6px rgba(0, 0, 0, 0.404);
        }
    }
}

.itw-analytics{
    .paragraph{
        @media screen and (max-width:1000px) {
            .image{
                width: 100%;
            }
        }
    }
    .section-selector{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        button{
            border: 0;
            background-color: #035AA6;
            width: 100%;
            color: aliceblue;
            font-weight: 500;
            letter-spacing: 1.5px;
            padding: 15px;
        }
        button:hover{
            background-color: #003e74;
            letter-spacing: 1.8px;
        }
        .selected-section{
            background-color: #003e74
        }
    }
    .marks{
        padding-top: 30px;
        h3{
            span{
                font-size: 1.8rem;
                font-weight: 700;
                color: #035297;
            }
            text-align: center;
            color: #035AA6;
        }
    }

    .questions-container{
        margin: 10px auto 10px auto;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        padding: 5px 30px;
        border-radius: 15px;
        min-width: 80%;
        width: 80%;
    
        .question{
            position: relative;
            margin: 30px 0 10px 0;
            width: 100%;
            .ques-line1{
                position: relative;
                display: flex;
                align-items: flex-start;
                justify-content: left;
                .qus-number{
                    h1{
                        background-color: #022B5E;
                        width: 50px;
                        height: 50px;
                        text-align: center;
                        color: #edf3ff;
                        border-radius: 10px;
                        margin-right:20px;
                    }
                }
                p{
                    font-size: 1.2rem;
                    padding-right: 20px;
                }
                .markForReview{
                    height: 40px;
                    position: absolute;
                    right: -23px;
                    cursor: pointer;
                    img{
                       height: 100%;
                       width: 100%;
                    }
                    img:hover{
                        height: 110%;
                        width: 110%;
                    }
                }
    
            }
            .question-Img{
                width: 400px;
                text-align: center;
                margin: 10px auto;
                max-height: 400px;
                img{
                    height: 100%;
                    width: 100%;
                    max-height: 400px;
                }
            }
            .options{
                margin: 20px;
                width: 100%;
                height: 100%;
                .option{
                    display: inline-block;
                    position: relative;
                    padding-left: 35px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    font-size: 1rem;
                    width: max-content;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    font-size: 1rem;
                    width: 100%;
                    input{
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                    }
                    .checkmark{
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 25px;
                        width: 25px;
                        background-color: #eee;
                        border-radius: 50%;
                    }
                }
                .option:hover input ~ .checkmark{
                    background-color: #ccc;
                }
                .option input:checked ~ .checkmark {
                    background-color: #022b5eaf;
                }
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }
    
                .option input:checked ~ .checkmark:after {
                    display: block;
                }
                .option .checkmark:after {
                    top: 9px;
                    left: 9px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #edf3ff;
               }
            }
        }
    }
}