.timer{
    position: fixed;
    right: 20px;
    display: flex;
    align-items: center;
    border: 0.2px solid rgba(0, 0, 0, 0.308);
    padding: 5px;
    border-radius: 15px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
    z-index: 3;
    background-color: inherit;
    img{
        height: 30px;
        margin: 0 10px ;
    }
    p{
        margin: 0 10px ;
        font-size: 30px;
        color:#035AA6;
    }
}
