.personal-details {

    button {
        padding: 5px 40px;
        border-radius: 10px;
        border-style:none;
        background-color: #002A5D;
        color: white;
        margin:  20px 0 ;
        cursor: pointer;
        width: 100%;
    }
    

    div {
        display: flex;
    flex-direction: column;
    width: 100%;


        input {
            width: 100%;
            background: none;
            padding: 10px 10px;
            border: 1px solid var(--text-color);
            border-radius: 10px;
            color: var(--text-color);
        }

        label {
            color: var(--text-color);
    margin: 10px 0 10px 0;
    font-size: 16px;
        }
        span {
            text-align: end;
            p {
                margin: 0;
            }
        }
    }
}

.add-user-pic {
    margin: 20px 0;

    width:100%;
   text-align: center;

    img {
        margin: auto;
    }

    button {
        padding: 5px 40px;
        border-radius: 10px;
        border-style:none;
        background-color: #002A5D;
        color: white;
        margin:  20px 0 ;
        cursor: pointer;
        width: fit-content;
    }
}


.otp-style {
    height: 100vh;
        width: 50vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: fixed;
        color: white;
        width: 70%;
    
        input {
            color: #002A5D;
    // text-align: center;
    // padding: 10px;
    border-radius: 5px;
    border: none;
    width: 30px!important;
    height: 30px;

        }

        input:focus {
            width: 40px;
            height: 40px;
            font-size: larger;
        }

        button {
            padding: 5px 40px;
            border-radius: 10px;
            border-style:none;
            background-color: #002A5D;
            color: white;
            margin:  20px 0 ;
            cursor: pointer;
            width: fit-content;
        }

}

@media (max-width:600px) {
    .otp-style {
        height: 100vh;
            width: 50vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: static;
            color: white;
            width: 70%;
        
            input {
                color: #002A5D;
        // text-align: center;
        // padding: 10px;
        border-radius: 5px;
        border: none;
        width: 30px!important;
        height: 30px;
    
            }
    
            input:focus {
                width: 40px;
                height: 40px;
                font-size: larger;
            }
    
            button {
                padding: 5px 40px;
                border-radius: 10px;
                border-style:none;
                background-color: #002A5D;
                color: white;
                margin:  20px 0 ;
                cursor: pointer;
                width: fit-content;
            }
    
    }
}
