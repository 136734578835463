
.schedule-popup{
    z-index: 10000;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.603);
    width: 100vw;
    height: 100vh;
    .card{
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto ;
        margin-right: auto ;
        top: 30%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 390px;
        height: 230px;
        border-radius: 20px;
        padding: 30px;
        background-color: #e6ecf9;
        h3{
            font-weight: 600;
        }
        .popup-btn{
            button{
                border:none;
                background: none;
                margin:20px;
                background: #0669F8;
                width: 120px;
                height: 30px;
                border-radius: 20px;
                color: #fff;
            }
        }
    }
}

@media only screen and (max-width: 726px) {
    .schedule-popup{
        .card{
            left: 15%;
        }
    }
}

@media only screen and (max-width: 600px) {
    .schedule-popup{
        .card{
            left: 10%;
        }
    }
}

@media only screen and (max-width: 500px) {
    .schedule-popup{
        .card{
            left: 3%;
        }
    }
}