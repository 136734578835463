.modal-custom {
    position: fixed;
    left:0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex!important;
    align-items: center;
    justify-content: center;
        z-index: 1;
        color: #002a5d;
}

.modal-custom .content {
    padding: 40px;
    width: 40%;
    background-color: white;
    overflow: hidden;
    box-shadow: 4px 4px 6px rgba(0, 0, 0 , 25%), inset 0px 0px 6px rgba(0, 0, 0,  25%);
    border-radius: 20px;
    background-color: #dfebef;
    color: #002a5d;
    

    p {
        text-align: center;
    }
}

.modal-custom button {
    padding: 5px 40px;
    border-radius: 10px;
    border-style:none;
    background-color: #002A5D;
    color: white;
}

.modal-custom .header, .footer {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    color: black;
}

.modal-custom body {
    padding: 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    color: black;
    text-align: center;
}

@media screen and (max-width:600px) {
    .modal-custom .content {
    padding: 10px;
    width: 90%;
    background-color: white;
    overflow: hidden;
}
.modal-custom button {
    padding: 5px 20px;
    border-radius: 10px;
    border-style:none;
    background-color: #002A5D;
    color: white;
}


}
