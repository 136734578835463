

.explore-btns {
    padding: 20px 0; 
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    font-size: 20px;
    margin: 0 0 20px 20px;
    flex-wrap: wrap;

    .highlight-btn {
        background-color: #002A5D;
        color: #e6ecf9;
    }

    button {
        background: none;
        border: 1px solid #002A5D ;
        border-radius: 25px;
        background-color: transparent;
        width: 170px;
        margin: 10px 0;
        color: var(--text-color);
        padding: 10px 0;
        height: 80px;
    }

    button:hover {
        transform: none;
    }
}

.explore-options {
    margin-top: 20px;
    padding: 10px 10px;
    background: white;
    border-radius: 25px;
    border-style: none;
    border-bottom: outset;
    border-right: outset;
    width: fit-content;
    color: #002A5D;

    select {
        border: none;
        background: none;
    }
}

