.HomeLoading{
    .img {
        width: 123px;
        height: 123px;
        border-radius: 50%;
        object-fit: scale-down;
        margin: auto;
        margin-bottom: 30px;
    }
    .text{
        width: 100%;
        height: .8rem;
        margin-bottom: .25rem;
        border-radius: .125rem;
    }
    .text:last-child{
        width: 70%;
        // margin-left: auto;
        // margin-right: auto;
    }
    .schedule-card{
        height: 15rem;
        background-color: #e6ecf9;
        cursor: normal;
    }
    .Slidecard1{
        .text{
            width: 100%;
            height: .8rem;
            margin-bottom: .25rem;
            border-radius: .125rem;
        }
        .text:last-child{
            width: 70%;
            // margin-left: auto;
            // margin-right: auto;
        }
    }
}