@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&family=Lora:ital,wght@0,400;0,600;0,700;1,500&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  // overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
}
html {
   background-color: #edf3ff !important;
   overflow-y: auto !important;
   z-index: -1;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    background-color: #edf3ff;
  
    min-width: 700px;
}
.main-Container{
  background-color:#edf3ff;
}

main {
  background-color: #edf3ff;
  // background-color: #edf3ff;
  min-height: 100vh;
  // border-radius: 5px 0 5px 0 !important;
}

.swiper-wrapper {
    padding: 9px 0;
}

.slick-next:before,
.slick-prev:before{
  color: rgb(6, 105, 248);
}

:root {
  --app-primary: #002a5d;
  --app-secondary: #fff;
  --text-color: #002A5D;
  --background-color: #effbff;
}

.linear-activity {
  position: absolute;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 4px;
  background-color: #adb9ff;
  margin: 0 auto;
}

.indeterminate {
  position: relative;
  width: 100%;
  height: 100%;
}

.indeterminate:before {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #2c4cff;
  animation: indeterminate_first 1.5s infinite ease-out;
}

.indeterminate:after {
  content: '';
  position: absolute;
  height: 100%;
  background-color: #4329b8;
  animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
  0% {
      left: -100%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}

@keyframes indeterminate_second {
  0% {
      left: -150%;
      width: 100%;
  }
  100% {
      left: 100%;
      width: 10%;
  }
}
.skeleton{
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0%{
      background-color: hsl(200, 20%, 70%);
  }

  100%{
      background-color: hsl(200, 20%, 95%);
  }
}