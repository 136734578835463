.live-test-overview {
    color: var(--text-color);
    background-color: var(--background-color);
    height: 100vh;
}

.test-info {
    margin:30px 90px;
    p{
        white-space: pre-wrap;
    }
}




.test-banner {
    height: 50vh;
    width: 100%;
    background-color: var(--text-color);
    padding: 10px;

    img {
        width: 300px;
        display: block;
        margin: auto;
    }
}

.test-content {

    border-radius: 31px;
    position: relative;
 
    h1,h2,h3 {
        color: var(--text-color);
    }
}

.test-details {
    background-color: #EEF1F6;
    padding: 20px;
    border-radius: 6px;
    box-shadow:2px 2px 6px rgba(0,0,0,0.3);
    width: max-content;
    line-height: 2;
}

.image-position {
    img {
        display: block;
        margin: auto;
    }
}

.enroll-btn {
    padding: 5px 40px;
    border-radius: 10px;
    border-style:none;
    background-color: #002A5D;
    color: white;
    margin: 10px 0;
    cursor: pointer;
}