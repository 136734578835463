.test-profile{
    width: 280px;
    height: 280px;
    background-color: #d7e7fa;
    border-radius: 20px;
    margin: 30px auto 40px auto;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 20px;
    h4{
        text-align: center;
        margin: 0 auto 20px auto;
        color: #035AA6;
        font: 400;
        font-size: 25px;
    }
    .img-container{
        width: 80px;
        height: 80px;
        background-color: rgb(255, 255, 68);
        margin: 0 auto 0 auto;
        border-radius: 50%;
        position: relative;
        background-color: #0669F8;
        border: 1px solid #77b0ff;
        img{
            height: 100% !important;
            width: 100% !important;
            border-radius: 50%;
        }
        .img-letter{
            h1{
                margin: 0px ;
                color: #d7e7fa;
                padding: 2px;
                font-size: 60px;
                text-align: center;
            }
        }
    }
    .test-profile-info{
        margin-top: 25px;
        width: 100%;
        text-align: center;
    }
    .test-profile-info p {
        margin: 5px;
        color: #035AA6;
    }
}

.test-marks{
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    border: 1px solid rgba(0, 0, 0, 0.466);
    width: 70%;
    height: 70px;
    margin: 20px auto 10px auto;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    img{
        height: 50px;
        width: 50px;
    }
    p{
        padding-top:10px ;
        font-weight: 600;
        font-size: 20px;
    }
}

.instructions{
    flex-direction: column;
    margin: 50px auto 10px auto;
    h1{
        text-align: center;
        font-weight: 600;
        color: #035AA6;
    }
    .instruction-body{
        border: 0.2px solid rgba(0, 0, 0, 0.514);
        padding:10px 40px 10px 40px;
        border-radius: 15px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        background-color: #d7e7faa8;
        margin: 40px auto 20px auto;
        width: 70%;
        height: fit-content;
        p{
            text-align: left;
            margin:10px 0 10px 0;
        }
    }
}
.checkBtn{
    width: 70%;
    height: fit-content;
    margin: 40px auto 10px auto;
    label{
        display: flex;
        align-items: flex-start;
        justify-content: left;
        p{
            margin-left: 20px;
            margin-top: 0;
        }
        .checkBox{
            margin-top: 5px;
        }
    }
}

.attempt{
    width: 70%;
    height: fit-content;
    margin: 30px auto 10px auto;
    button{
        margin: 0 auto 0 auto;
        border-radius: 20px;
        color: rgb(255, 255, 255);
        background-color: #013f75;
        padding-left: 50px ;
        padding-right: 50px ;
        display: block;
        border: 0;
        padding: 10px 30px;
    }
    .disable{
        cursor: no-drop;
        background-color: #0059a7b7;
    }
}

.instruction-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    img{
        width: 40px;
        height: 40px;
    }
    p{
        display: inline;
        margin-left: 10px;
        font-size: 1.2rem;
        font-weight: 600;
        color:#035AA6 ;
    }
}

.test-Container{
    width: 100%;
    height: 100%;
    main{
        .Container{
            .section-btns{
                margin: 30px;
                margin-top: 60px;
                display: flex;
                align-items: center;
                justify-content: center;
                button{
                    border: 0;
                    margin: 0 20px;
                    height: 50px;
                    width: fit-content;
                    background-color: transparent;
                    color: black;
                    padding-left: 20px;
                    padding-right: 20px;
                    border-radius: 10px;
                }
                .selected-section{
                    background-color: rgb(6, 105, 248);
                    color: white;
                }
                button:hover{
                    background-color: rgb(6, 105, 248);
                    color: white;
                }
            }
            .questions-Container{
                overflow-y: scroll;
                height: 360px;
                padding: 20px 10px;
                .questions-notfound{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    margin: 40px;
                }
            }
            .question-tracking{
                overflow-y: scroll;
                height: 360px;
                padding: 20px 10px;
                .tracker-container{
                    border: rgba(0, 0, 0, 0.253) solid 0.2px;
                    .tracker:first-child{
                        border-top: 0;
                    }
                    .tracker{
                        width: 100%;
                        background-color: #e4e7eb;
                        height: fit-content;
                        padding: 20px 10px;
                        border: rgba(0, 0, 0, 0.253) solid 0.2px;
                        border-right: 0;
                        border-left: 0;
                        .tracker-section{
                            width: fit-content;
                            height: 50px;
                            background-color: rgb(6, 105, 248);
                            color: white;
                            border-radius: 10px;
                            padding: 13px 20px;
                        }
                        .tracker-no-of-ques{
                            margin: 20px 0;
                        }
                        .tracker-balls{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .ball-cont{
                                display: flex;
                                align-items: center;
                                .ball{
                                    background-color: brown;
                                    margin: 10px;
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 50%;
                                    border: 0.1px solid rgba(0, 0, 0, 0.295);
                                }
                                p{
                                    margin: 0;
                                }
                            }
                            
                        }
                    }
                    .tracker-reviews{
                        min-height: 50px;
                        h6{
                            margin: 10px;
                        }
                        .tracker-review-inner{
                            display: flex;
                            align-items: center;
                            
                            div{
                                color: white;
                                border-radius: 50%;
                                margin: 10px;
                                width: 25px;
                                height: 25px;
                                border-radius: 50%;
                                background-color: rgba(255, 0, 0, 0.637);
                                text-align: center;
                                border: 1px solid rgba(0, 0, 0, 0.479);
                            }
                        }
                    }
                }
            }
        }
        .submitBtn{
            margin: 30px auto;
            color: white;
            border-radius: 15px;
            padding: 10px 50px 10px 50px;
            background-color: rgb(6, 105, 248);
            border: 0;
            display: block;
        }
    }
}

.blur{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.753);
    z-index: 2;
    .content{
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        width: 80%;
        height: 80vh;
        z-index: 2;
        background-color: rgb(255, 255, 255);
        margin: 10vh auto;
        border-radius: 15px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        svg{
            margin-bottom: 50px;
        }
        p{
            text-align: center;
            font-size: 1.1rem;
            font-weight: 600;
            letter-spacing: 0.6px;
        }
        button{
            margin: 25px;
            background-color: #035AA6;
            color: whitesmoke;
            padding: 10px 30px;
            font-size: 1rem;
            font-weight: 600;
            letter-spacing: 0.8px;
            border: 0;
            border-radius: 5px;
            box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        }
        button:hover{
            background-color: #003b6e;
            letter-spacing: 1px;
        }
    }
}
