.main,
.foundational-assignments{
    .dropdown {
        position: relative;
        display: inline-block;
      
        .dropbtn {
            background-color: #035aa6de;
            color: white;
            padding: 16px;
            font-size: 16px;
            border: none;
            cursor: pointer;
            width: 200px;
            font-size: 20px;
            border-radius: 5px;
        }
        .dropbtn:hover, .dropbtn:focus {
            background-color: #035aa6;
        }
        .dropdown-content {
            display: none;
            position: absolute;
            background-color: #f1f1f1;
            min-width: 160px;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
            p{
                color: black;
                padding: 12px 16px;
                display: block;
            }
            p:hover {
                background-color: #ddd
            }
        }
        .show {
            display:block;
        }
    }
    .questions-container{
        width: 100%;
        margin-top: 20px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
        padding: 20px;
        .question{
            margin: 10px 0 10px 0;
            border-radius: 15px;
            .ques-line1{
                display: flex;
                align-items: flex-start;
                justify-content: left;
                .qus-number{
                    h1{
                        background-color: #022B5E;
                        width: 50px;
                        height: 50px;
                        text-align: center;
                        color: #edf3ff;
                        border-radius: 10px;
                        margin-right:20px ;
                    }
                }
                p{
                    font-size: 20px;
                }
            }
            .question-Img{
                width: 400px;
                text-align: center;
                margin: 10px auto;
                max-height: 400px;
                img{
                    height: 100%;
                    width: 100%;
                    max-height: 400px;
                }
            }
            .options{
                margin: 20px 20px 20px 40px;
                .option{
                    display: block;
                    position: relative;
                    padding-left: 35px;
                    margin-bottom: 12px;
                    cursor: pointer;
                    font-size: 22px;
                    -webkit-user-select: none;
                    -moz-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    input{
                        position: absolute;
                        opacity: 0;
                        cursor: pointer;
                    }
                    .checkmark, .wrong, .right{
                        position: absolute;
                        top: 0;
                        left: 0;
                        height: 25px;
                        width: 25px;
                        background-color: rgb(180, 180, 180);
                        border-radius: 50%;
                    }
                }
                .option:hover input ~ .checkmark{
                    background-color: #ccc;
                }
                .option input:checked ~ .checkmark {
                    background-color: #022b5eaf;
                }
                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                .option input:checked ~ .checkmark:after {
                    display: block;
                }
                .option .checkmark:after {
                    top: 9px;
                   left: 9px;
                   width: 8px;
                   height: 8px;
                   border-radius: 50%;
                   background: #edf3ff;
                }

               .option:hover input ~ .wrong{
                    background-color: rgb(207, 207, 207);
                }
                .option input:checked ~ .wrong {
                    background-color: #e70808af;
                }
                .wrong:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                .option input:checked ~ .wrong:after {
                    display: block;
                }
                .option .wrong:after {
                    top: 9px;
                    left: 9px;
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background: #edf3ff;
                }


                .option:hover input ~ .right{
                    background-color: #ccc;
                }
                .option input:checked ~ .right {
                    background-color: #02c73d;
                }
                .right:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                .option input:checked ~ .right:after {
                    display: block;
                }
                .option .right:after {
                    top: 9px;
                   left: 9px;
                   width: 8px;
                   height: 8px;
                   border-radius: 50%;
                   background: #edf3ff;
                }
            }
        }
        .correctAnswer{
            span{
                margin-left: 10px;
                font-weight: 600;
                font-size: 18px;
            }
        }
        .explainAnswer{
            button{
                border:0;
                // background-color: transparent;
                background-color: #035aa6;
                padding: 5px 25px;
                color: white;
                border-radius: 5px;
            }
            button:hover{
                background-color: #024b8a;
            }
        }
    }
}