.foundational-assignments{
    background-color: #edf3ff !important;
    section{
        .a{
            cursor: pointer;
            display: block;
            background-color: rgba(0, 0, 0, 0);
            height: fit-content;
            width: fit-content;
            padding: 10px;
            border-radius: 10px;
            img{
                width: 35px;
            }
        }
        .dropbtn{
            width: fit-content !important;
        }
        .marks{
            padding-top: 30px;
            h3{
                span{
                    font-size: 1.8rem;
                    font-weight: 700;
                    color: #035297;
                }
                text-align: center;
                color: #035AA6;
            }
        }
        .questions-notfound{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin: 40px;
        }
    }
}