.practice-analytics,
.foundational-assignments{
    .section-selector{
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        button{
            border: 0;
            background-color: #035AA6;
            width: 100%;
            color: aliceblue;
            font-weight: 500;
            letter-spacing: 1.5px;
            padding: 15px;
        }
        button:hover{
            background-color: #003e74;
            letter-spacing: 1.8px;
        }
        .selected-section{
            background-color: #003e74
        }
    }
    .question-scroller{
        label{
            font-size: 18px;
            font-weight: 500;
        }
        input{
            width: 45px;
            text-align: center;
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
        }

        /* Firefox */
        input[type=number] {
        -moz-appearance: textfield;
        }
        button{
            margin: 0 10px;
            width: 100px;
            border: none;
            background-color: #003e74;
            color: #dfebef;
            padding: 4px;
            border-radius: 5px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
            cursor: pointer;
        }
        button:hover{
            box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.4);
        }
    }
    .question-details{
        margin-top: 40px;
        h3{
            font-size: 1.5rem;
            font-weight: 700;
            color: #035297;
        }
        span{
            font-weight: 700;
            color: #073f70;
        }
        p{
            font-size: 1rem;
            font-weight: 500;
            color: #035297;
        }
    }
    .section-info{
        width: fit-content;
        padding: 30px 45px;
        border-radius: 25px;
        margin: 20px auto;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, 
        rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        h3{
            font-size: 1.3rem;
            font-weight: 500;
            color: #035297;
        }
        span{
            color: #043c6d;
            font-weight: 700;
        }
    }
    .marks{
        padding-top: 30px;
        h3{
            span{
                font-size: 1.8rem;
                font-weight: 700;
                color: #035297;
            }
            text-align: center;
            color: #035AA6;
        }
    }
    .Notice{
        width: calc(100% - 100px);
        height: fit-content;
        min-height: 500px;
        border-radius: 15px;
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25),
            inset 4px 4px 6px rgba(0, 0, 0, 0.25);
        background-color: #dfebef;
        margin: 0 auto 0 auto;
        img{
            display: block;
            width: 250px;
            min-width: 250px;
            margin: 80px auto 80px auto;
        }
        div{
            width: 90%;
            min-width: 300px;
            height: fit-content;
            margin: 20px auto;
            padding: 20px;
            text-align: center;
            h3{
                color: #003e74;
                word-spacing: 2px;
            }
        }
    }
}


.answer-cards{
    width: 90%;
    margin: 10px auto;
    h5{
        color:#003e74;
        font-weight: 600;
    }
    .textArea{
        width: 100%;
        height:250px;
        background-color: #ffffff;
        border: solid rgba(7, 7, 7, 0.63) 1px;
        margin: 20px auto;
        padding: 20px;
        border-radius: 10px;
        overflow: scroll;
        overflow-x: hidden;
    }
    
    .textArea::-webkit-scrollbar{
        width: 5px;
    }
    .textArea::-webkit-scrollbar-track {
        background-color: rgb(255, 255, 255);
    }
    .textArea::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
    }
    
    .textArea::-webkit-scrollbar-button {
        width: 10px;
        height: 10px;
    }

    .container{
        width:100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        position: relative;
        height:auto;
        margin-top: 20px;
        max-height:300px;
        overflow-y:auto;
        .image {
            height: 85px;
            width: 85px;
            border-radius: 5px;
            box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
            overflow: hidden;
            position: relative;
            margin-bottom: 7px;
            margin-right: 7px;
        }
        .image:nth-child(4n) {
            margin-right: 0;
        }
        .image img {
            height: 100%;
            width: 100%;
        }
        .image{
            .delete, 
            .preview {
                position: absolute;
                right: 5px;
                cursor: pointer;
                font-size: 22px;
                color: rgb(56, 37, 37);
            }
            .preview:hover,
            .delete:hover {
                opacity: 0.8;
            }
            .preview{
                top:-4px;
            }
            .delete{
                top: 18px;
            }
        } 
        .fileName{
            width: 120px ;
            padding: 10px;
            font-size: 12px;
        }
    }
}

.file-preview-container{
    background-color: rgba(0, 0, 0, 0.493);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 5;
    .file-preview-card{
        width: 80%;
        height: 80vh;
        background-color: #e4e7eb;
        margin: 10vh auto ;
        border-radius:10px ;
        box-shadow: 0  0 5px  rgba(0, 0, 0, 0.493);
        position: relative;
        .close_btn{
            position: absolute;
            top:10px;
            right: 10px;
            border: none;
            background-color: red;
            color: #fff;
            border-radius: 50%;
            padding: 1px;
            width: 28px;
            height: 28px;
            font-weight: 700;
            transition: 0.3s;
        }
        .close_btn:hover{
            color: #fff;
            transition: 0.3s;
            background-color: rgb(172, 9, 9);
        }
        display: flex;
        align-items: center;
        justify-content: center;
        .file{
        
            .pdf{
                height: 80vh;
                overflow-x: hidden;
                border-radius: 10px;
            }
            .pdf::-webkit-scrollbar {
                width: 5px;
            }
            
            .pdf::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
            }
            
            .pdf::-webkit-scrollbar-thumb {
                background-color: darkgrey;
                outline: 1px solid slategrey;
            }
            img{
                max-width: 100%;
                max-height: 80vh;
            }
        }
    }
}

.question-scroller{
    label{
        font-size: 18px;
        font-weight: 500;
    }
    input{
        width: 45px;
        text-align: center;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
    button{
        margin: 0 10px;
        width: 100px;
        border: none;
        background-color: #003e74;
        color: #dfebef;
        padding: 4px;
        border-radius: 5px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
        cursor: pointer;
    }
    button:hover{
        box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.4);
    }
}