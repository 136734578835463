.notes{
    padding: 20px;
    text-decoration: none;
    list-style-type:none;
    .content{
        .current-topic{
            width: 180px;
            padding: 5px;
            cursor: pointer;
            border: 1px solid #28395e;
            border-radius: 7px;
            background-color: #28395e;
            box-shadow: inset 0 4px 8px rgba(255, 255, 255, 0.21);
            color:#fff;
            float: right;
        }
        .subjects{
            h5{
                margin: 10px;
                font-family: 'Lato', sans-serif !important;
                font-size: 30px;
                font-weight: 700;
                text-decoration: underline;
            }
            margin-bottom: 20px;
            .chapter{
                margin: 10px 30px;
                font-family: 'Lato', sans-serif !important;
                font-size: 21px;
                font-weight: 600;
                .topics{
                    margin: 10px 30px;
                    font-family: 'Lato', sans-serif !important;
                    font-size: 18px;
                    font-weight: 400;
                    p{
                        width: calc(100% - 100px);
                    }
                    .underLine{
                        border-bottom: 3px solid rgb(0, 92, 128);
                    }
                    button{
                        width: 100px;
                        height: 30px;
                        margin-left: 20px;
                        border: none;
                        background-color: rgb(37, 120, 245);
                        color: #fff;
                        border-radius: 5px;
                        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.29);
                    }
                    button:hover{
                        box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.29) !important;
                    }
                    button:disabled{
                        display:none
                    }
                }
            }
        }
    }
    .pdf-modal{
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: black;
        z-index: 50;
    }
}