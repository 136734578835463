.practice-test {
    color: var(--text-color);
}

.no-practice-tests {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10%;
 }


 .practice-card {
    width: 320px;
    padding: 25px;
    border-radius: 18px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25),
    inset 4px 4px 6px rgba(0, 0, 0, 0.25);
    background: #e6ecf9;
    align-items: center;
    justify-content: space-around;
    margin: 10px;
    color: var(--text-color);

    a {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    a:hover {
        text-decoration: none;
    }

    img {
        height: 100%;
        width: 100%;
        margin: 20px auto;
    }

    .practice-details {
        width: 100%;
    

   
        
        p {
            margin-top: 13%;
            font-size: 25px;
            color: rgb(1, 42, 93);
            text-align: center;
        }
     
        .price{
            margin-top: 5%;
            font-size: 20px;
            color:rgb(1, 42, 93) ;
            text-align: center;
        }
    }

 


}