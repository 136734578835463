.main-Container{
    background-color:#edf3ff;
}
.main {
    margin-left: 180px;
}
@media screen and (max-width:726px) {
    .main {
        margin-left: 0;
    }
}
.boxShadow {
    box-shadow: 3px 4px 5px #00000021;
}
.input-group-text, .form-control {
    background-color: #EEF1F6;
    font-weight: 400;
    
    border: none;
    border-radius: 20px;
}
.content-notAvail{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h3{
        margin-top: 20px;
        margin-bottom: 20px;
    }
}
.form-control {
    color: #002A5D!important;
}


.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #002A5D;
    opacity: 0.8; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #002A5D;
    opacity: 0.8; /* Firefox */
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
    color: #002A5D;
    opacity: 0.8; /* Firefox */
}

// shadow remove for text

textarea:hover, 
input:hover, 
textarea:active, 
input:active, 
textarea:focus, 
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
    outline:0px !important;
    -webkit-appearance:none;
    box-shadow: none !important;
}

.filterGrp {
    background-color: rgb(32, 88, 220);
    padding: 18px 23px;
    border-radius: 7px;
    margin-left: 20px;
    img {
        width: 22px;
    }
}


.no-lessons {
    background: #9FB3DB;
    color: white;
    text-align: center;
    padding: 6px;
    border-radius: 32px;
}



.Slidecard {
    border-radius: 18px;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25),
    inset 4px 4px 6px rgba(0, 0, 0, 0.25);
    background: #e6ecf9;
    display: flex !important;
    padding: 40px 20px;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    width: 94% !important;
    min-height: 320px;
    height: fit-content;
    margin: 10px 10px;
    align-items: center;
    justify-content: space-around;
    img {
        width: 165px;
        height: 123px;
        object-fit: scale-down;
        margin: auto;
    }
    p {
        margin-top: 13%;
        font-size: 1.1rem;
        font-weight: 600;
        color: rgb(1, 42, 93);
        text-align: center;
        // word-wrap:break-word
    }
    .authors{
        margin-top: 5%;
        font-size: 15px;
        color:rgba(1, 42, 93, 0.87) ;
        text-align: center;
    }
    .progress {
        background-color: #adbee0 !important;
        /* height: 7px; */
        border-radius: 11px;
        margin: 5px auto;
        position: relative;
    }
    .progress-bar {
        background-color: #035AA6!important;
    }
}

.assignments{
    a{
        display: flex !important;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        .assignment-card {
            position: relative;
            width: 200px;
            height: 220px;
            border-radius: 25px;
            text-decoration: none;
            color: aliceblue;
            margin: 0;
            box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 4px rgba(0, 0, 0, 0.25);
            div{
                position: absolute;
                bottom: 10px;
                left: 20px;
                margin: 0;
            }
            p{
                margin: 5px 0 0 0;
                text-decoration: none !important;
            }
            img{
                margin-bottom: 20px;
                position: absolute;
                top:-5px;
                right: -13px;
            }
        }
    }
}

