.Live-test-series{
    .assignment-expired{
        background-color:  rgba(173, 16, 16, 0.945) !important;
        cursor: no-drop;
    }
    .assignment-card{
        height: 315px !important;
        .assignment-info{
            height: 100px !important;
        }
    }
}
