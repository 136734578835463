.MainPreB4Pre{
    color: var(--text-color);
    background-color: var(--background-color);
    height: 100vh;
    .banner {
        height: 40vh;
        width: 100%;
        background-color: var(--text-color);
        padding: 10px;
        div{
            background-color: #effbff;
            width: fit-content;
            min-width: 450px;
            margin: auto;
            padding: 50px;
            border-radius: 15px;
            .IMG {
                width: 250px;
                display: block;
                margin: auto;
            }
        }
    }
    .content{
        width: 80%;
        margin: 100px auto 10px auto;
        font-size: 17px;
        min-width: 300px;
        background-color: #effbff;
        h1{
            margin: 20px 0;
        }
        .button-container{
            background-color: #effbff;
            width: 80%;
            margin: 10px auto 10px auto;
            display: flex;
            justify-content: center;
            align-items: center;
            p{
                margin: 0;
                font-size: 21px;
                font-weight: 500;
            }
            button{
                margin: 30px 30px 30px 30px;
                border: none;
                background: none;
                width: 200px;
                height: 50px;
                background: #002a5d;
                color: #effbff;
                border-radius: 15px;
                font-size: 18px;
            }
        }
    }
    
}