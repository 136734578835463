.search-box {
    display: flex;
    align-items: center;
    padding:  0 20px;
    background: var(--text-color);
    color: white !important;
    box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 70%;
    min-width: 250px;
    margin: 0 auto 20px auto;
  input {
    padding:  15px;
    color: white;
}
}