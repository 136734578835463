@import "~bootstrap/scss/bootstrap";

.redirect{
    z-index: 1000000;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.89);
    width: 100vw;
    height: 100vh;
    top: 0;
    .redirect-card{
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto ;
        margin-right: auto ;
        top: 30%;
        width: 320px;
        height: 250px;
        border-radius: 20px;
        background-color: #e6ecf9;
        padding: 20px;
        h3{
            font-size: 21px;
            font-weight: 600;
            text-align: center;
        }
        p{  
            margin: 20px auto 20px auto;
            width: 90%;
            text-align: center;
            font-weight: 400;
        }
        hr {
            border: 0;
            height: 1px;
            background: #333;
            background-image: linear-gradient(to right, #ccc, #333, #ccc);
        }
        button{
            display: block;
            margin: 10px auto 10px auto;
            background: none;
            border: none;
            font-size: 18px;
            color: rgb(66, 66, 255);
        }
        .popup-btn{
            button{
                border:none;
                background: none;
                margin:20px;
                background: #f80e06;
                width: 100px;
                height: 30px;
                border-radius: 20px;
                color: #fff;
            }
        }
    }
}
