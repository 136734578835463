.select-item{
    padding-top: 20px;
    padding-bottom: 20px;
    background-color:transparent;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    button{
        border: none;
        background-color: transparent;
        color: #002A5D;
        padding:15px;
        width: 200px;
        font-size: 20px;
        border: 1px solid #002A5D;
        border-radius: 25px;
    }
    .selected-item{
        background-color: #002A5D;
        color:#e6ecf9;
    }
}

.practice-test-overview{
    .test-content{
        margin-left: 20px;
    }
    .test-banner {
        height: 50vh;
        width: 100%;
        background-color: var(--text-color);
        padding: 10px;
        img {
            width: 200px;
            display: block;
            margin: auto;
        }
    }
}
@media screen and (max-width:726px) {
    .practice-test-overview {
        .test-content{
            margin-left: 0;
        }
    }
    .test-details{
        width: 70vw;
    }
}
.practice-test-btn{
    border: 0;
    background-color: #002A5D;
    padding: 10px 20px;
    text-align: center;
    width: 100%;
    margin: 0 20px 20px 20px ;
    color: white;
    border-radius: 15px;
    font-weight: 500;
}
.pre{
    margin: 0 auto 20px auto ;
    width: 60%;
}
.pre:first-child{
    margin: 70px auto 20px auto ;
}

.practice-test-btn:hover{
    background-color: #01234d;
    color: white;
}

.practice-test-btn:disabled{
    cursor:no-drop;
    background-color: #01234dbb;
}
