.questions-container{
    margin-top: 10px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    padding: 5px 30px;
    border-radius: 15px;
    min-width: 100%;
    width: fit-content;
    .question{
        position: relative;
        margin: 30px 0 30px 0;
        width: 100%;
        .ques-line1{
            position: relative;
            display: flex;
            align-items: flex-start;
            justify-content: left;
            .qus-number{
                h1{
                    background-color: #022B5E;
                    width: 50px;
                    height: 50px;
                    text-align: center;
                    color: #edf3ff;
                    border-radius: 10px;
                    margin-right:20px;
                }
            }
            p{
                font-size: 1.2rem;
                padding-right: 20px;
            }
            .markForReview{
                height: 40px;
                position: absolute;
                right: -23px;
                cursor: pointer;
                img{
                   height: 100%;
                   width: 100%;
                }
                img:hover{
                    height: 110%;
                    width: 110%;
                }
            }

        }
        .question-Img{
            width: 400px;
            text-align: center;
            margin: 10px auto;
            max-height: 400px;
            img{
                height: 100%;
                width: 100%;
                max-height: 400px;
            }
        }
        .options{
            margin: 20px;
            width: 100%;
            height: 100%;
            .option{
                display: inline-block;
                position: relative;
                padding-left: 35px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: 1rem;
                width: max-content;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                font-size: 1rem;
                width: 100%;
                input{
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                }
                .checkmark{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 25px;
                    width: 25px;
                    background-color: #eee;
                    border-radius: 50%;
                }
            }
            .option:hover input ~ .checkmark{
                background-color: #ccc;
            }
            .option input:checked ~ .checkmark {
                background-color: #022b5eaf;
            }
            .checkmark:after {
                content: "";
                position: absolute;
                display: none;
            }

            .option input:checked ~ .checkmark:after {
                display: block;
            }
            .option .checkmark:after {
                top: 9px;
                left: 9px;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: #edf3ff;
           }
        }

        .card {
            width:100%;
            height:auto;
            padding:15px;
            box-shadow: 0 0 5px rgba(0,0,0, 0.15);
            border-radius: 5px;
            overflow: hidden;
            background: #fafbff;
            .sun-editor{
                width: 100% !important;
                .se-list-layer{
                    top:40px !important
                }
            }
            .top{
                font-size:0.9rem;
                font-weight:600;
                color:#878a9a;
                span{
                    color: #022B5E;
                }
                .progress-label{
                    position: absolute;
                    color: #000;
                    display: block;
                    margin: 1px auto;
                    text-align: center;
                    left: 48%;
                }
                button{
                    outline: 0;
                    border:0;
                    -webkit-appearance: none;
                    background:#5256ad;
                    color:#fff;
                    border-radius:4px;
                    transition: 0.3s;
                    cursor: pointer;
                    box-shadow: 0 0 5px rgba(0,0,0, 0.15);
                    font-size:0.8rem;
                    padding: 8px 13px;
                }
                button:hover{
                    opacity: 0.8;
                }
                button:active{
                    transform: translateY(5px);
                }
            }
            .form{
                width: 100%;
                height: 160px;
                border-radius: 5px;
                border: 1px dashed #d5d5e1;
                color: #c8c9dd;
                font-size: 0.9rem;
                font-weight: 500;
                position: relative;
                background: #dfe3f259;
                display: flex;
                justify-content: center;
                align-items: center;
                user-select: none;
                margin-top: 20px;
                flex-direction: column;
                .select{
                    color:#5256ad;
                    margin-left: 7px;
                    cursor: pointer;
                }
                input{
                    display: none;
                }
                .dragover{
                    border-style: solid;
                    font-size: 2rem;
                    color: #c8c9dd;
                    font-weight:600;
                    background: rgba(0, 0, 0, 0.34);
                }
            }
            .container{
                width:100%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                position: relative;
                height:auto;
                margin-top: 20px;
                max-height:300px;
                overflow-y:auto;
                .image {
                    height: 85px;
                    width: 85px;
                    border-radius: 5px;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
                    overflow: hidden;
                    position: relative;
                    margin-bottom: 7px;
                    margin-right: 7px;
                }
                .image:nth-child(4n) {
                    margin-right: 0;
                }
                .image img {
                    height: 100%;
                    width: 100%;
                }
                .image{
                    .delete, 
                    .preview {
                        position: absolute;
                        right: 5px;
                        cursor: pointer;
                        font-size: 22px;
                        color: rgb(56, 37, 37);
                    }
                    .preview:hover,
                    .delete:hover {
                        opacity: 0.8;
                    }
                    .preview{
                        top:-4px;
                    }
                    .delete{
                        top: 18px;
                    }
                } 
                .fileName{
                    width: 120px ;
                    padding: 10px;
                    font-size: 12px;
                }
            }
        }
    }
}