.buttonc{
    display: flex;
    justify-content: center;
    background-color: #035AA6;
    color: aliceblue;
    padding: 5px;
    .pagec{
        margin: 0 30px 0 30px;
    }
    button{
        border: 0;
        background-color: transparent;
        color: aliceblue;
    }
    button:disabled{
        color: rgb(141, 149, 156);
        cursor:no-drop;
    }
}


.pdf {
    height: 70vh;
    overflow: auto;

    .sticky-btn {
        position: sticky;
        bottom: 0;
    }

    .react-pdf__Document {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .react-pdf__Page__canvas {
        margin: 0 auto;
        width: 100% !important;
        height: 100% !important;
    }
}
