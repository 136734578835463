.logout-popup{
    z-index: 10000;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.603);
    width: 100vw;
    height: 100vh;
    .card{
        position: absolute;
        left: 35%;
        top: 30%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 320px;
        height: 180px;
        border-radius: 20px;
        background-color: #e6ecf9;
        h3{
            font-weight: 600;
        }
        .popup-btn{
            button{
                border:none;
                background: none;
                margin:20px;
                background: #f80e06;
                width: 100px;
                height: 30px;
                border-radius: 20px;
                color: #fff;
            }
        }
    }
}

@media only screen and (max-width: 726px) {
    .logout-popup{
        .card{
            left: 15%;
        }
    }
}

@media only screen and (max-width: 600px) {
    .logout-popup{
        .card{
            left: 10%;
        }
    }
}

@media only screen and (max-width: 500px) {
    .logout-popup{
        .card{
            left: 3%;
        }
    }
}