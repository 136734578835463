.installment-tag{
    text-align: right;
    font-size: 12px;
    width: 100%;
    color: rgb(68, 68, 226);
    text-decoration: underline;
    cursor: pointer;
    
}
.installment-tag svg{
    width: 12px;
    padding-bottom: 2px;
}

table{
    width: 80%;
    margin: 10px auto;
    padding: 10px;
    border: 1px solid rgba(14, 4, 0, 0.473);
    box-shadow: rgba(14, 4, 0, 0.473) 0 4px 8px;
}
table tr td,
table tr th{
    padding-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.534);
}

table tr:nth-child(odd){
    background-color: #effbff;
    color: #002A5D;
}
table tr:nth-child(even){
    background-color: #002A5D;
    color: #effbff;
}