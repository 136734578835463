.enroll-foundation {
    .nav-back{
        height: 70px;
        width: 100vw;
    }
    .nav-bar{
        position: fixed;
        width: 100vw;
        height: 70px;
        background-color: #066bf8;
        z-index: 50;
        div{
            position: relative;
            .img-container{
                position: absolute;
                display: flex;
                align-items: center;
                height: 70px;
                left: 5px;
                max-width: 300px;
                h3{
                    margin: 0;
                    color: #e1e8f1;
                    font-size: 18px;
                }
                img{
                    display: inline;
                    height:60px;
                    padding: 1px;
                }
                .backBtn{
                    cursor: pointer;
                    background-color: transparent;
                    padding: 5px;
                    border-radius: 5px;
                    height: 35px;
                    width: 30px;
                    margin-right:10px ;
                }
                margin: 0px 50px;
            }
            .right-content{
                position: absolute;
                z-index: 3;
                right: 30px;
                top: 18px;
                padding: 1px;
                margin: 0px 50px;
                .dropD{
                    .foundational-menu{
                        font-size: 18px;
                        background-color: transparent;
                        border: none;
                        color: #f2f4f7;
                    }
                    .foundational-menu:hover{
                        color: #ffffff96;
                    }
                    position: relative;
                    .drop-menu{
                        position: absolute;
                        right: 0;
                        top: calc(100% + .25rem);
                        background-color: #fff6f6;
                        padding: 0;
                        border-radius: 0.25rem;
                        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .3);
                        opacity: 0;
                        transform: translateY(-10px);
                        transition: opacity 150ms ease-in-out, transform 150ms ease-in-out;
                        pointer-events: none;
                        width: 180px;
                        max-height:700px;
                        overflow-y: auto;
                        overflow-x: hidden;
                        .items{
                            border: none;
                            background-color: #f3eded;
                            text-decoration: none;
                            height:40px;
                            width: 180px;
                            text-align: left;
                            padding-left: 20px;
                            display:table-cell;
                            vertical-align: middle; 
                            margin: auto 0 auto 0;
                            color: black;
                            box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, .1);
                        }
                        .items:hover{
                            background-color: #ece7e7;
                        }
                        .items:first-child{
                            border-radius: 0.25rem 0.25rem 0 0;
                        }
                    }
                }
                .dropD.active{
                    .drop-menu{
                        opacity: 1;
                        transform: translateY(0);
                        pointer-events: auto;
                    }
                    .foundational-menu{
                        color: #f2f4f7;
                    }
                }
            }
        }
    }
    .foundational-List::-webkit-scrollbar{
        width: 5px;
    }
    .foundational-List::-webkit-scrollbar-track {
        background-color: rgb(255, 255, 255);
    }
    .foundational-List::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 1);
    }
    .foundational-content{
        position: fixed;
        top:71px;
        right: 0;
        width: 300px;
        height: calc(100vh - 71px);
        background-color: #ffffff;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 8px;

        .heading{
            width: 100%;
            height: 60px;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 8px;
            h4{
                text-align: center;
                overflow: hidden;
                font-weight: 600;
                font-size: 21px;
                font-family: 'Lora', serif;
                margin: 0;
            }
            button{
                background-color: transparent;
                border: none;
                margin: 0;
            }
        }

        .foundational-List{
            height:calc(100% - 60px);
            overflow-y: scroll;
            background-color: #ffffff;
            scrollbar-width: thin;
            // scrollbar-color: #6969dd #e0e0e0;
            .subjects{
                div{
                    background-color: #fffdfd !important;
                }
                .chapters{
                    padding: 0;
                    /* background-color: #ffffff !important; */
                    div{
                        background-color: #f2f4f7 !important;
                    }
                    .topics{
                        padding: 0;
                        border-left:none ;
                        border-right:none ;
                        .selected{
                            background-color: #29405f !important;
                        }
                        div{
                            background-color: rgb(252, 253, 253) !important;
                            button{
                                text-align: left;
                                border: none;
                                background-color: transparent;
                                height: fit-content;
                            }
                            button:disabled{
                                cursor: no-drop;
                            }
                        }
                        .selected{
                            background-color: #e1e8f1 !important;
                        }
                    }
                }
            }
        }
    }
    
    .foundational-info-container{
        width: calc(100% - 300px);
        height: fit-content;
    }
    .video-player{
        width: calc(100% - 300px);
        height: 60vh;
        min-height: 430px;
        background-color: black;
    }
    .foundational-info{
        .foundational-info-bar{
            width:calc(100% - 300px);
            height: fit-content;
            box-shadow: rgba(0, 0, 0, 0.24) 0px 4px 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            .foundational-info-item{
                border: 1px solid rgba(0, 0, 0, 0.301);
                border-left: none;
                width: 100%;
                height: 50px;
                text-align: center;
                button{
                    border: none;
                    width: 100%;
                    height: 100%;
                    background-color: #ffffff;
                    font-family: 'Lora', serif;
                    font-weight: 800;
                }
                .selected,
                button:hover{
                    background-color: #ececec;
                }
            }
        }
    }
}
