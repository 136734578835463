.foundational-card{
    background-color: #e6ecf9;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.3);
    height: 340px;
    position: relative;
    margin: 20px auto;
    width: 280px !important;
    max-width: 280px;
    cursor: pointer;
    .container{
        width: 100%;
        padding: 25px 0; 
        .image{
            position: absolute;
            width: 100%;
            height: 140px;
            left: 0;
            right: 0;
            top: 0;
            border-radius: 5px;
            box-shadow: 0 4px 8px rgba(0,0,0,0.3);
            img{
                width: 100%;
                height: 100%;
                border-radius: 5px;
            }
            .noimage{
                width: 94%;
                margin-left:auto ;
                margin-right:auto ;
                display: block;
            }
        }
        .stars-wrappers{
            position: absolute !important;         
            top:146px;
            right: 20px;
        }
        .title{
            position: absolute;
            width: 96%;
            height: 110px;
            left: 2%;
            right: 2%;
            top:184px;
            .text{
                text-align: center;
            }
        }
        .pg{
            position: absolute;
            width: 96%;
            height: 110px;
            left: 2%;
            right: 2%;
            top:290px;
            .progress{
                height: 6px;
                border: 1px solid rgba(0, 0, 0, 0.247);
                .progress-bar{
                    background-color: #09076d !important;
                }
            }
            p{
                font-size: 14px;
                text-align: center;
                width: 100%;
                margin-top: 5px;
                font-weight: 500;
                font-family: 'Lato', sans-serif;
            }
        }
    }
}